// 作为全局组件
class Notify{

    constructor(){
        this.options = {
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        };
    }

    warn(text){
        return this.tips(text, 'warning');
    }

    success(text){
        return this.tips(text, 'success');
    }

    info(text){
        return this.tips(text, 'info');
    }

    error(text){
        return this.tips(text, 'error');
    }

    alert(text){
        return this.tips(text, 'warning');
    }

    tips(text, type){
        let options = {};
        if (typeof text === 'object') {
            Object.assign(options, this.options, text, {
                'type': type || 'alert'
            });
        } else {
            Object.assign(options, this.options, {
                'text': text,
                'type': type || 'alert'
            });
        }
        Swal.fire(options);
    }
}

Notify.getInstance = function(){
    if (Notify.instance) {
        return Notify.instance;
    }
    return Notify.instance = new Notify();
};

export default Notify.getInstance();