import NProgress from 'nprogress';
import Notify from './component/notify.js';

const qimify = {
    // 文档
    $document: $(document),

    // 自动加载
    loadingBar: NProgress,

    notify: Notify,

    /**
     * 绑定 boot 事件
     * @param callback
     */
    boot: function(callback){
        this.$document.on('qimify.boot', callback);
    },

    /**
     * 绑定 init 事件
     * @param callback
     */
    init: function(callback){
        this.$document.on('qimify.init', callback);
    },
};
window.qimify = qimify;